<template>
  <div class="iss-main theme-bg">
    <div class="invalid-content">
      <tips-bar text="您可以编辑无效联系人信息并转为有效联系人。">
        <template #btnList>
          <div class="btn" @click="clickHandle">返回</div>
        </template>
      </tips-bar>
      <div class="operation">
        <div class="operation-table">
          <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            tableTitle="无效联系人"
            :urlParams="urlParams"
            formWidth="500px"
            :items="items"
            :url="url"
            :btn-operation="btnOperation"
            :showHeader="true"
            allowSelection
            :scroll="{ y: gridHeight }"
          >
            <template #avatar="{ record }">
              <div class="nameClass">
                <div class="surname" v-if="record.name?.length > 0">
                  {{ record.name?.charAt(0) }}
                </div>
                <!--                <div class="nameText" :title="record.name">{{ record.name }}</div>-->
              </div>
            </template>
            <template #infoList="{ record }">
              <span v-for="(i, index) in record.infoList" :key="i.id">
                {{ i.phone }}
                <span v-if="index !== record.infoList.length - 1">,</span>
              </span>
            </template>
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
          </grid>
        </div>
        <div class="operation-pagination"></div>
      </div>
    </div>
  </div>
  <!-- 转为有效联系人失败人员名单 -->
  <a-modal
    v-model:visible="state.errorVisible"
    okText="一键删除"
    @ok="deletePeople"
    :width="900"
    :cancel-button-props="{ style: { display: 'none' } }"
  >
    <div class="fs-16">重复联系人</div>
    <div class="mt-20">
      <ExclamationCircleFilled class="l-text-primary fs-20" />
      <span class="fs-18">
        此部分联系人电话号码在有效联系人中已存在，请勿重复添加。
      </span>
    </div>
    <div class="selectAll mt-20 mb-10">
      <a-checkbox @change="selectAllChange">全选</a-checkbox>
    </div>
    <a-table
      :columns="errorTable.columns"
      row-key="id"
      :data-source="errorTable.dataSource"
      :pagination="errorTable.pagination"
      :showHeader="false"
      :row-selection="{
        selectedRowKeys: errorTable.ids,
        onChange: onSelectChange,
      }"
    >
      <template #infoList="{ record }">
        <span v-for="(i, index) in record.infoList" :key="i.id">
          {{ i.phone }}
          <span v-if="index !== record.infoList.length - 1">,</span>
        </span>
      </template>
    </a-table>
  </a-modal>
  <a-modal
    title="编辑"
    v-model:visible="state.visible"
    okText="确认"
    @ok="editPeople"
  >
    <a-form
      ref="formRef"
      layout="vertical"
      style="width: 100%"
      :model="formState"
      :rules="rules"
    >
      <a-form-item label="姓名" name="name">
        <a-input v-model:value="formState.name" />
      </a-form-item>
      <a-form-item label="电话" name="phone">
        <a-input v-model:value="formState.infoList[0]['phone']" />
      </a-form-item>
      <a-form-item label="公司" name="company">
        <a-input v-model:value="formState.company" />
      </a-form-item>
      <a-form-item label="职位" name="position">
        <a-input v-model:value="formState.position" />
      </a-form-item>
    </a-form>
  </a-modal>

  <edit-page-one
    v-model:visible="state.editVisible"
    :init="state.initValue"
    @fnOk="handleAddPage"
  />
</template>

<script>
import tipsBar from './components/tipsBar';
import { useRouter, useRoute } from 'vue-router';
import Grid from '@/components/connGrid';
import { createVNode, getCurrentInstance, reactive, ref, toRaw, watch } from 'vue';
import { Checkbox, Table, Form, message, Modal } from 'ant-design-vue';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import operation from '@/components/operation';
import { validMobile } from '@/utils/validation';
import dashboardApi from '@/api/dashboard.js';
import editPageOne from '@/views/dashboard/components/editPageOne';
import { isDelBoundary } from '@/utils';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'invalid',
  components: {
    tipsBar,
    Grid,
    ExclamationCircleFilled,
    AForm: Form,
    AFormItem: Form.Item,
    ACheckbox: Checkbox,
    ATable: Table,
    operation,
    editPageOne,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref();
    const gridRef = ref();

    const state = reactive({
      errorVisible: false,
      editVisible: false,
      initValue: {},
      visible: false,
      loading: false,
      search: {},
    });
    const formState = reactive({
      name: '',
      infoList: [],
      company: '',
      position: '',
    });
    const errorTable = reactive({
      ids: [],
      pagination: {
        showLessItems: true,
        showSizeChanger: true,
        showQuickJumper: true,
        size: 'default',
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        showTotal: total => `共 ${total} 项数据`,
      },
      columns: [
        {
          dataIndex: 'name',
          key: 'name',
        },
        {
          dataIndex: 'company',
          key: 'company',
        },
        {
          dataIndex: 'infoList',
          key: 'phone',
          slots: { customRender: 'infoList' },
        },
      ],
      dataSource: [],
    });
    const rules = {
      name: [
        {
          required: true,
          message: '请输入姓名',
          trigger: 'change',
        },
      ],
      phone: [
        {
          required: true,
          trigger: 'change',
          validator: async () => {
            if (formState.infoList[0]['phone'].trim() === '') {
              return Promise.reject('请输入手机号');
            } else if (!validMobile(formState.infoList[0]['phone'].trim())) {
              return Promise.reject('手机格式不合法');
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    };
    const clickHandle = () => {
      router.back();
    };
    proxy.$bus.on('CONN-SEARCH', obj => {
      state.search = obj;
    });

    const editPeople = () => {
      formRef.value.validateFields().then(() => {
        if (state.loading) return;
        state.loading = true;
        const obj = toRaw(formState);
        delete obj.birthday;
        delete obj.infoList[0].status;
        dashboardApi
          .updatePeople({
            userId: store.state.account.user.id,
            ...toRaw(formState),
          })
          .then(res => {
            if (res) {
              message.success('编辑成功');
              state.loading = false;
              state.visible = false;
              gridRef.value.refreshGrid();
            }
          })
          .catch(() => {
            message.error('操作失败');
            state.loading = false;
          });
      });
    };

    const selectAllChange = e => {
      errorTable.ids = e.target.checked
        ? errorTable.dataSource.map(i => i.id)
        : [];
    };
    const onSelectChange = ids => {
      errorTable.ids = ids;
    };
    const deletePeople = () => {
      if (state.loading || !errorTable.ids.length) return;
      state.loading = true;
      dashboardApi
        .deletePeople({
          userId: store.state.account.user.id,
          ids: errorTable.ids,
        })
        .then(res => {
          if (res) {
            message.success('删除成功');
            state.loading = false;
            errorTable.dataSource = errorTable.dataSource.filter(
              i => !errorTable.ids.includes(i.id)
            );
            errorTable.ids = [];
            gridRef.value.refreshGrid();
          }
        })
        .catch(() => {
          message.error('操作失败');
          errorTable.ids = [];
          state.loading = false;
        });
    };
    watch(
      () => state.errorVisible,
      val => {
        if (!val) {
          gridRef.value.refreshGrid();
        }
      }
    );
    watch(
      () => state.visible,
      val => {
        if (!val) {
          formRef.value.clearValidate();
        }
      }
    );
    return {
      editPeople,
      clickHandle,
      selectAllChange,
      onSelectChange,
      deletePeople,
      formRef,
      state,
      gridRef,
      rules,
      errorTable,
      formState,
      url: '/connections_treasure_box/contact/import-contact/page',
      urlParams: {
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        effective: 2,
      },
      gridHeight: document.body.clientHeight - 280,
      items: [
        {
          key: 'abnormal',
          label: '类型',
          type: 'select',
          dataset: [
            { value: '', label: '全部' },
            { value: '2', label: '无号码' },
            { value: '1', label: '无姓名' },
          ],
        },
        { key: 'name', label: '搜索姓名、公司、职位' },
      ],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: '',
          btnType: 'primary',
          permission: 'clue:delete',
          fnClick: () => {
            // if (ids.length) {
            //   console.log('ids',  gridRef.value.selectAll())

            const { total  } = gridRef.value.isTotal();
            // selectAll 是否跨页全选， cancelRowsIdLength 取消勾选Id 数组长度, total 总数， id 勾选id 数组长度
            let delStu = isDelBoundary(
              gridRef.value.selectAll().selectAll,
              gridRef.value.returnCancelRowsId().length,
              total,
              gridRef.value.returnSelectedRowKeys().length
            )
            if (delStu) {
              dashboardApi
                .removeByContactId({
                  userId: store.state.account.user.id,
                  ids: gridRef.value.selectAll().contactId,
                  importSituation: route.query.importStatus,
                  effective: 2,
                  selectAll: gridRef.value.selectAll().selectAll,
                  abnormal: state.search.abnormal,
                  name: state.search.name,
                })
                .then(res => {
                  if (res) {
                    state.loading = false;
                    message.success('删除成功');
                    gridRef.value.refreshGrid(gridRef.value.selectAll().contactId.length);
                  }
                })
                .catch(() => {
                  message.error('操作失败');
                  state.loading = false;
                });

            } else {
              return message.info('请选择数据');
            }

            // }
          },
        },
        {
          label: '转为有效',
          btnType: 'default',
          permission: 'change',
          fnClick: (ids, data) => {

            if (gridRef.value.selectAll().selectAll) {
              return message.error('转为有效功能不支持跨页全选')
            }

            if (ids.length && data.length > 0) {
              const arr = data.filter(i => {
                return ids.includes(i.id);
              });
              const canChange = arr.some(i => {
                return (
                  i.name?.length == 0 ||
                  !i.infoList ||
                  i.infoList?.length == 0 ||
                  i.infoList[0].phone.length === 0
                );
              });
              if (canChange) {
                message.error('转为有效联系人，姓名和手机号不能为空');
              } else {
                if (state.loading) return;
                state.loading = true;
                const obj = {
                  userId: store.state.account.user.id,
                  importSituation: route.query.importStatus,
                  importContactIdList: [...ids],
                  effective: 2,
                };
                dashboardApi
                  .putEffect1(obj)
                  .then(res => {
                    if (res) {
                      dashboardApi
                        .putEffect2(obj)
                        .then(res => {
                          if (res.length == 0) {
                            dashboardApi
                              .putEffect3({
                                ...obj,
                                type: 4,
                              })
                              .then(res => {
                                if (res) {
                                  message.success('转换成功');
                                  gridRef.value.refreshGrid();
                                  state.loading = false;
                                }
                              })
                              .catch(() => {
                                message.error('操作失败');
                                state.loading = false;
                              });
                          } else {
                            state.loading = false;
                            state.errorVisible = true;
                            errorTable.dataSource = res;
                          }
                        })
                        .catch(() => {
                          message.error('操作失败');
                          state.loading = false;
                        });
                    } else {
                      state.loading = false;
                      message.error('存在重复联系人');
                    }
                  })
                  .catch(() => {
                    message.error('操作失败');
                    state.loading = false;
                  });
              }
            }
          },
        },
      ],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 180,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          title: '公司',
          dataIndex: 'company',
          width: 240,
          ellipsis: true,
        },
        {
          title: '职位',
          dataIndex: 'position',
          width: 150,
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'infoList',
          slots: { customRender: 'infoList' },
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'id',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'invalid:update',
          fnClick: record => {
            state.editVisible = true;
            if (
              !record.infoList ||
              record.infoList.length === 0 ||
              !record.infoList[0].phone
            ) {
              record['infoList'] = [];
              record['infoList'][0] = {
                userId: record.userId,
                phone: '',
              };
            }
            // Object.assign(formState, JSON.parse(JSON.stringify(record)));
            Object.assign(state.initValue, JSON.parse(JSON.stringify(record)));
          },
        },
      ],
      handleAddPage(value) {
        state.editVisible = value;
        gridRef.value.refreshGrid();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.invalid-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.operation {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  .operation-header {
    .header-text {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}
.surname {
  width: 36px;
  height: 36px;
  display: inline-block;
  background-color: @primary-color;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  //line-height: 36px;
  color: #fff;
  //margin-right: 8px;
  min-width: 36px;
  padding-top: 4px;
}
.iss-main /deep/ .ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
}
.nameName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .surname {
  }
  .nameText {
    width: 100px;
  }
}
</style>
